.Prompt-main{
    flex: 1; /* Takes remaining space after header */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
}

.Prompt-form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 1rem;
}

@media (min-width: 968px) {
    .Prompt-text {
        width: 25rem;
    }
  }
  


.Prompt-text {
    min-width: 20rem;
}

@media (min-width: 968px) {
    .Prompt-logo{
        max-width: 10rem;
        margin-bottom: 1rem;
    }
}

.Prompt-logo{
    max-width: 8rem;
    margin-bottom: 1rem;
}

.Prompt-components{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Prompt-retry {
    cursor: pointer;
    color: red;
    padding-top: 1rem;
    font-weight: 600;
}