.AuthImage-main {
    display: flex;
    justify-content: center;
    flex-direction: column;
}
@media (min-width: 968px) {
    .AuthImage-main {
        display: flex;
        justify-content: center;
        flex-direction: row;
    }
  }
  

.AuthImage-empty-column{
    flex-grow: 1;
}

.AuthImag-info-column{
    flex-grow: 1;
    flex-basis: min-content;
}
.AuthImage-image{
    flex-grow: 0;
}