@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(360deg);
    /* First spin */
  }

  50% {
    transform: rotate(1080deg);
    /* Adds two more spins, increasing speed */
  }

  75% {
    transform: rotate(3240deg);
    /* Doubles the spins, increasing speed further */
  }

  100% {
    transform: rotate(3240deg);
    /* Stops and holds the final position */
  }
}

@keyframes glowing-red {
  0% {
    fill: #000000;
    /* Original color */
    filter: drop-shadow(0 0 0 red);
  }

  50% {
    fill: red;
    filter: drop-shadow(0 0 8px red);
  }

  100% {
    fill: #000000;
    /* Original color */
    filter: drop-shadow(0 0 0 red);
  }
}

.robot-eye-left-glow circle {
  animation: glowing-red 2s infinite;
  /* Adjust the duration as needed */
}

.robot-eye-right-glow circle {
  animation: glowing-red 2s infinite;
  /* Adjust the duration as needed */
}

.robot-eye-left {
  animation: spin 4s ease-in-out infinite;
  /* Adjust time as needed */
  transform-origin: 59.1% 61.0%;
}

.robot-eye-left-glow {
  transform-origin: 59.1% 61.0%;
}
.robot-foreground {
  fill: var(--robot-foreground);
}

.robot-background {
  fill: var(--robot-background);  
}

.robot-main{
  fill: #e6e6e6;
}