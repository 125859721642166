.Image-main2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust the height as needed */
    padding: 40px; /* Padding to ensure some space around the edges */
    box-sizing: border-box; /* Include padding in the height calculation */
  }
  .Image-main {
    flex: 1; /* Takes remaining space after header */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
  }
  .Image-image {
    max-width: 100%; /* Adjust image size as needed */
    max-height: 80vh;
    height: auto;
    margin: auto; /* This centers the image horizontally */
  }
  
  .Image-caption {
    text-align: center; /* Center-align the caption text */
    margin-top: 10px; /* Space between image and caption */
    max-width: 50rem;
  }