.Processing {
    display: flex;
    align-items: center;
}
.Processing-logo{
    width: 6rem;
    padding-right: 1rem;
}
.Processing-text {
    display: inline-block; /* Allows the pseudo-element to position correctly */
    position: relative; /* Establishes a positioning context for pseudo-elements */
    font-size: 2rem;
}

.Processing-text::after {
    content: ''; /* Initial content is empty */
    animation: typing 1.5s steps(3, end) infinite; /* Define the animation */
    position: absolute; /* Position the pseudo-element relative to its parent */
}

@keyframes typing {
    0%, 25% { content: ''; }
    26%, 50% { content: ' .'; }
    51%, 75% { content: ' ..'; }
    76%, 100% { content: ' ...'; }
}