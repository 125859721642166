:root {
  --background-color: #ffffff; /* default light theme background color */
  --main-color: #21222c; /* default light theme text color */
  --button-color: #a23154;
  --button-hover: #be2f5b;
  --robot-foreground: #21222c;
  --robot-background: #ffffff;
  --header-border: #e6e6e6;
  --textarea-background: #fbfcfe;
  --textarea-border: #cdd7e1;
  --textarea-text: #21222c;
}

.App-main {
  display: flex;
  flex-direction: column;
  height: 100lvh; /* Full viewport height */
  overflow: auto; /* In case content exceeds viewport */
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  background-color: var(--background-color);
  color: var(--main-color);
}

.App-dark {
  --background-color: #21222c; /* default light theme background color */
  --main-color: #ffffff; /* default light theme text color */
  --robot-foreground: black;
  --header-border: #32333d;
  --textarea-background: #262833;
  --textarea-border: #32343e;
  --textarea-text: #C0C0C0;
}