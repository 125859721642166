.Details-main {
    margin: 1rem;
    
}

.Details-row {
    padding-bottom: 0.8rem;
    display: flex;
    padding-right: 0.5rem;

}

.Details-editable {
    cursor: pointer;
}

.Details-row strong {
    padding-right: 0.5rem;
    
}

.Details-row-edit {
    padding-bottom: 0.8rem;
    display: flex;
    align-items: center;
}

.Details-row-edit strong {
    padding-right: 0.5rem;
    
}

.Details-max{
    text-align: right;
}

.Details-seed{
    font-family: "Fira Code", sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
}