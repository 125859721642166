.Menu-main {
    background-color: var(--background-color);
    color: var(--main-color);
    height: 100%;

}
.Menu-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--header-border);
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
    text-align: center;
}

.Menu-items{
    font-size: 1.2rem;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.Menu-items a {
    text-decoration: none;
    color: white;
}

.Menu-button {
    height: 1.5rem;
    fill: var(--main-color)
}

.Menu-button svg {
    height: 2rem;
    fill: var(--main-color)
}