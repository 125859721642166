.Header-main {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--header-border);
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
    text-align: center;
}

.Header-main a {
    text-decoration: none;
    color: var(--main-color);
}

.Header-logo {
    padding-right: 1rem;
    display: flex;
    align-items: center;
}

.Header-logo-text{
    padding-left: 0.2rem;
    font-weight: 700;
    font-size: 1.3rem;
    letter-spacing: -0.1rem;
}

.Header-logo img {
    width: 2rem;
}

/* .Header-path {
    font-size: 1.2rem;
    font-weight: 400;
    display: flex;
} */
.Header-path {
    display: none;
}

@media (min-width: 968px) {
    .Header-path {
        font-size: 1.2rem;
        font-weight: 400;
        display: flex;
    }
  }
  

.Header-link {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
}

.Header-link-active {
    font-weight: 600;
}

.Header-theme{
    flex-grow: 1;
    display: flex;
    /* flex-direction: row-reverse; */
    justify-content: flex-end;
}

.Header-theme-switch-left {
    margin-left: 0.5rem;
}

.Header-theme-switch-right {
    margin-right: 0.5rem;
}

.Header-menu > :first-child {
    display: block;
}

@media (min-width: 968px) {
    .Header-menu > :first-child {
      display: none;
    }
  }

.Header-menu{
    height: 1.5rem;
}

.Header-switch > :first-child {
    display: none;
}

@media (min-width: 968px) {
    .Header-switch > :first-child {
      display: block;
    }
  }