.Logo-main {
    width: 2rem;
}

.Logo-foreground {
    /* fill: var(--main-color) */
    fill: url(#gradient);
}

.Logo-background {
    fill: var(--background-color);
}

.gradient2 {
    background-color: #bdcad9;
    background-image: linear-gradient(315deg, #bdcad9 0%, #e1dada 74%);
}